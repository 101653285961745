/* Dialogs */

.ant-modal .ant-modal-content {
  padding: 34px 20px 30px;
}

.ant-modal-confirm .ant-modal-confirm-paragraph {
  max-width: none;
}

.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: var(--brand);
  display: none;
}

.ant-modal-confirm .ant-modal-confirm-title {
  font-size: 20px;
  text-align: center;
  text-wrap: balance;
}

.ant-modal-confirm .ant-modal-confirm-content {
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

/* Notifications */

/* Info icon */
.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-icon-info.anticon {
  color: var(--brand);
}

/* Cross button */
.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-close:hover {
  color: var(--brand);
}

.custom-dropdown {
  position: relative;
  width: 234px;
}

.custom-dropdown__close {
  position: absolute;
  left: 100%;
  bottom: 100%;
}
