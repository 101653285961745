.sidebar {
  --hover-color: rgb(255 255 255 / 15%);
}

.sidebar {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  bottom: 0;
  width: var(--sidebar-width);
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  gap: 4%;
  color-scheme: dark;
  background-color: var(--charcoal);
  /* background-image: url("../images/sidebar.jpg"); */
  /* background-size: cover; */
  /* background-repeat: no-repeat; */
  /* background-position: center center; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-resolution: 2dppx) {
  .sidebar {
    /* background-image: url("../images/sidebar@2x.jpg"); */
  }
}

.sidebar__top {
}

.sidebar__logo {
  display: block;
  width: 100%;
  border: 0 none;
  height: 110px;
  background: transparent;
  background-image: url("../images/meed\ logo-vertical\ white.svg");
  background-size: 60px 90px;
  background-repeat: no-repeat;
  background-position: center center;
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.sidebar__logo:hover {
  background-color: var(--hover-color);
}

.sidebar__logo:active {
  translate: 0 1px;
}

@media (min-resolution: 2dppx) {
  .sidebar__logo {
    background-image: url("../images/meed\ logo-vertical\ white.svg");
  }
}

.sidebar__beta {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: -7px;
  padding: 1px 11px 0;
  background-color: var(--cream);
  border-radius: 6px;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: var(--charcoal);
  text-transform: uppercase;
}

.sidebar__version {
  margin-top: 3px;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: var(--cream);
}

.sidebar__programs {
  position: relative;
}

.sidebar__current-program {
  position: relative;
  display: inline-block; /* if used as <button> or <a> */
  width: 100%; /* if used as <button> or <a> */
  padding: 20px 0 25px;
  border: none;
  background-color: transparent;
  text-align: center;
  transition: background-color var(--transition-hover);
}

a.sidebar__current-program:hover,
a.sidebar__current-program:hover {
  /* background-color: var(--hover-color); */
  background-color: var(--cream);
}

button.sidebar__current-program:active,
button.sidebar__current-program:active {
  translate: 0 1px;
}

.sidebar__photo {
  position: relative;
  vertical-align: top;
  width: 60px;
  height: 60px;
  box-shadow:
    0 0 0 5px var(--cream),
    0 10px 0 rgb(245 251 255 / 30%);
  border-radius: 15px;
}

.sidebar__dropdown {
  position: absolute;
  left: 100%;
  top: 0;
  padding-left: 15px;
  padding-top: 15px;
}

.sidebar__dropdown::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 100;
  right: 100%;
  top: 105px; /* 105 is a height of .sidebar__current-program */
  height: calc(100% - 105px); /* 105 is a height of .sidebar__current-program */
  width: 60px; /* value is based on experimenting */
  transform: skewX(16deg); /* value is based on experimenting */
  transform-origin: left top;
}

.sidebar__drop-window {
  position: relative;
  z-index: 200;
  background-color: #ffffff;
  box-shadow: 4px 0 10px 0 rgb(0 0 0 / 0.25);
  border-radius: 15px;
  color-scheme: light;
  overflow: hidden;
}

.sidebar__middle {
}

.sidebar__link {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 0;
  text-align: center;
  border: 0 none;
  background: transparent;
  font-family: inherit;
  cursor: pointer;
}

.sidebar__link:disabled {
  opacity: 0.75;
}

.sidebar__link::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 5px;
  bottom: 0;
  background-color: white;
  /* background-color: var(--cream); */
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  opacity: 0;
  transition: opacity var(--transition-hover);
}

.sidebar__illustration {
  position: relative;
  vertical-align: top;
  width: 90px;
  height: 90px;
  fill: #ffffff;
}

.sidebar__link:hover {
}

.sidebar__link:hover::before {
  opacity: 20%;
}

.sidebar__link--current {
}

.sidebar__link--current::before,
.sidebar__link--current:hover::before {
  opacity: 100%;
}

.sidebar__link--current .sidebar__illustration {
  /* fill: url(#hoverGradient); */
  fill: var(--charcoal);
}

.sidebar__bottom {
  padding-top: 40px;
}

.sidebar__action {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  text-align: center;
  transition: background-color var(--transition-hover);
  cursor: pointer;
}

.sidebar__action:hover {
  background-color: var(--hover-color);
}

.sidebar__action:active {
  translate: 0 1px;
}

.sidebar__icon {
  vertical-align: top;
  width: 50px;
  height: 50px;
  fill: #ffffff;
}

@media (min-height: 874px) and (max-height: 1120px) {
  .sidebar {
    padding-top: 1.125%;
    padding-bottom: 1.125%;
    gap: 4.5%;
    background-size: cover;
  }

  .sidebar__top {
  }

  .sidebar__logo {
    height: 83px;
    background-size: 45px 66px;
  }

  .sidebar__beta {
    margin-top: -6px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .sidebar__current-program {
    padding-top: 15px;
    padding-bottom: 19px;
  }

  .sidebar__photo {
    width: 45px;
    height: 45px;
    box-shadow:
      0 0 0 4px #ffffff,
      0 8px 0 rgb(245 251 255 / 30%);
    border-radius: 11px;
  }

  .sidebar__dropdown {
    padding-left: 11px;
    padding-top: 11px;
  }

  .sidebar__dropdown::before {
    top: 79px; /* 79 is a height of .sidebar__current-program */
    height: calc(100% - 79px); /* 79 is a height of .sidebar__current-program */
    width: 50px; /* value is based on experimenting */
    transform: skewX(18deg); /* value is based on experimenting */
  }

  .sidebar__drop-window {
  }

  .sidebar__middle {
  }

  .sidebar__link {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .sidebar__link::before {
    right: 4px;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);
    border-top-right-radius: 11px;
    border-bottom-right-radius: 11px;
  }

  .sidebar__illustration {
    width: 68px;
    height: 68px;
  }

  .sidebar__bottom {
    padding-top: 30px;
  }

  .sidebar__action {
    height: 60px;
  }

  .sidebar__icon {
    width: 50px;
    height: 50px;
  }
}

@media (max-height: 873px) {
  .sidebar {
    padding-top: 1%;
    padding-bottom: 1%;
    gap: 4%;
    background-size: 67px 952px;
  }

  .sidebar__top {
  }

  .sidebar__logo {
    height: 73px;
    background-size: 40px 60px;
  }

  .sidebar__beta {
    margin-top: -4px;
    padding-left: 9px;
    padding-right: 9px;
    font-size: 9px;
  }

  .sidebar__current-program {
    padding-top: 13px;
    padding-bottom: 17px;
  }

  .sidebar__photo {
    width: 40px;
    height: 40px;
    box-shadow:
      0 0 0 3px #ffffff,
      0 7px 0 rgb(245 251 255 / 30%);
    border-radius: 10px;
  }

  .sidebar__dropdown {
    padding-top: 10px;
    padding-left: 10px;
  }

  .sidebar__dropdown::before {
    top: 70px; /* 70 is a height of .sidebar__current-program */
    height: calc(100% - 70px); /* 70 is a height of .sidebar__current-program */
    width: 40px; /* value is based on experimenting */
    transform: skewX(20deg); /* value is based on experimenting */
  }

  .sidebar__drop-window {
  }

  .sidebar__middle {
  }

  .sidebar__link {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .sidebar__link::before {
    right: 4px;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .sidebar__illustration {
    width: 60px;
    height: 60px;
  }

  .sidebar__bottom {
    padding-top: 26px;
  }

  .sidebar__action {
    height: 53px;
  }

  .sidebar__icon {
    width: 50px;
    height: 50px;
  }
}

@media (max-height: 740px) {
  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    display: block;
    overflow-y: auto;
  }

  .sidebar__top {
    padding-bottom: 30px;
  }

  .sidebar__bottom {
    padding-top: 20px;
  }

  .sidebar__dropdown {
    position: fixed;
    left: var(--sidebar-width);
    top: 0;
    margin: 0 !important;
  }
}
