.membership {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.membership__heading {
    margin-top: 0;
    margin-bottom: 0;
}

.membership__body {
    margin-top: var(--spacing);
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: var(--spacing);
}

.membership__section {
    padding: var(--spacing) var(--spacing) 42px;
    background-color: #FFFFFF;
    border-radius: 30px;
    box-shadow: var(--shadow-over-grey-background);
}

.membership__sub-heading {
    margin-top: 0;
    margin-bottom: 0;
}

.membership__description {
    margin-top: 2px;
    font-size: 15px;
    line-height: 20px;
    color: #5D6164;
}

.membership__description--smaller {
    font-size: 14px;
    line-height: 18px;
}

.membership__widget {
  margin-top: var(--spacing);
}

.membership__split-widget {
  margin-top: var(--spacing);
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: var(--spacing);
}

.membership__half {
}

.membership__half .label {
  white-space: nowrap;
}

.membership__campaign-preview {
  margin-top: var(--spacing);
}

.membership__campaign-preview-heading {

}

.membership__campaign-preview-widget {
}

.membership__settings {
  margin-top: calc(var(--spacing) * 2);
}

.membership__preview {
  margin-top: var(--spacing);
}

.membership__preview-heading {
  margin-top: 0;
  margin-bottom: 0;
}

.membership__voucher {
  margin-top: 12px;
}

.membership__submit {
  margin-top: var(--spacing);
}

.membership__submit-line-break {
  display: none;
}

.membership__qr-code {
  margin-top: var(--spacing);
}

.membership__preview-qr {
  border: 5px solid var(--primary-colour);
  border-radius: 30px;
  padding: 30px;
  position: relative;
  display: inline-block;
}

.membership__qr-image {
  vertical-align: top;
  width: 100%;
  height: auto;
}

.membership__qr-overlay {
    position: absolute;
    top: 50%; /* center vertically */
    left: 0;
    width: 100%;
    height: 40px; /* height of the overlay band */
    background-color: var(--primary-colour); /* the desired background color */
    color: white; /* text color */
    text-align: center; /* centers the text horizontally */
    line-height: 40px; /* centers the text vertically */
    transform: translateY(-50%); /* adjust the position to be in the middle */
    pointer-events: none; /* allows clicks to pass through to the image below */
}

.membership__qr-image--blur {
    /*    filter: blur(8px); */
    /*     opacity: 50%; */
}

.membership__download-qr-code {
    margin-top: var(--spacing);
}

.membership__create-voucher {
    margin-top: calc(2 * var(--spacing));
}

.membership__skip-voucher {
  margin-top: 16px;
}

.membership__pass {
  margin-top: 24px;
}

.membership__pass-head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.membership__pass-heading {
}

.membership__actions {
  display: flex;
  align-items: flex-start;
  margin-top: -8px;
  gap: 16px;
}

.membership__navigation {
  display: none;
}

@media (900px <= width < 1500px) {

    .membership {

    }

    .membership__heading {

    }

    .membership__body {

    }

    .membership__section {
        padding-bottom: 28px;
        border-radius: 20px;
    }

    .membership__sub-heading {

    }

    .membership__description {
        font-size: 13px;
        line-height: 18px;
    }

    .membership__description--smaller {
    font-size: 12px;
    line-height: 16px;
  }

  .membership__widget {
  }

  .membership__split-widget {
  }

  .membership__campaign-preview {
  }

  .membership__campaign-preview-heading {
  }

    .membership__campaign-preview-widget {

    }


    .membership__preview {

    }

    .membership__preview-heading {

    }

    .membership__voucher {
    margin-top: 8px;
  }

  .membership__submit {
  }

  .membership__submit-line-break {
    display: inline;
  }

  .membership__qr-code {
  }

  .membership__preview-qr {
    border: 3px solid var(--primary-colour);
    border-radius: 20px;
    padding: 20px;
    }

    .membership__qr-image {

    }

    .membership__qr-image--blur {

    }

    .membership__download-qr-code {

    }

    .membership__create-voucher {

    }

    .membership__skip-voucher {
        margin-top: 12px;
    }

  .membership__navigation {
  }
}

@media (width < 900px) {
  .membership {
    flex-grow: 1;
  }

  .membership__heading {
  }

  .membership__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .membership__section {
    flex-grow: 1;
    padding: var(--spacing) 32px 42px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: none;
  }

  .membership__section--mobile-current {
    display: block;
  }

  .membership__sub-heading {
  }

  .membership__description {
  }

  .membership__description--smaller {
  }

  .membership__widget {
  }

  .membership__split-widget {
  }

  .membership__campaign-preview {
  }

  .membership__campaign-preview-heading {
  }

  .membership__campaign-preview-widget {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  .membership__preview {
  }

  .membership__preview-heading {
  }

  .membership__voucher {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  .membership__submit {
  }

  .membership__submit-line-break {
  }

  .membership__qr-code {
    text-align: center;
  }

  .membership__preview-qr {
    max-width: 340px;
  }

  .membership__qr-image {
  }

  .membership__qr-overlay {
  }

  .membership__download-qr-code {
  }

  .membership__create-voucher {
  }

  .membership__skip-voucher {
  }

  .membership__navigation {
    display: block;
  }
}
