.uploader {
}

.uploader__handler {
  display: flex;
  align-items: center;
  gap: 19px;
  width: 100%;
  padding: 8px;
  border: none;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #5d6163;
  border-radius: var(--input-radius);
  font-family: inherit;
  text-align: left;
  cursor: pointer;
  transition: background-color var(--transition-hover);
}

.uploader__handler:hover {
  background-color: var(--white-hover);
}

.uploader__placeholder {
  flex-shrink: 0;
  vertical-align: top;
  width: 66px; /* Same as logo */
  margin-left: 4px; /* Same as logo */
  height: auto;
}

.uploader__message {
  flex-grow: 1;
  display: block;
  font-size: 15px;
  line-height: 18px;
}

.uploader__add {
  margin-right: 19px;
  width: 12px;
  flex-shrink: 0;
  height: auto;
}

.uploader__item {
  margin-top: 15px;
  gap: 17px;
  padding: 8px 9px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #5d6163;
  border-radius: var(--input-radius);
}

.uploader__item:first-child {
  margin-top: 0;
}

.uploader__preview {
  flex-shrink: 0;
  vertical-align: top;
  width: 66px; /* 10px smaller than column of buttons */
  margin-top: 4px; /* because it is smaller */
  margin-bottom: 4px; /* because it is smaller */
  margin-left: 4px; /* because it is smaller */
  height: auto;
  background-color: #ffffff;
}

.uploader__data {
  padding-top: 4px;
  padding-bottom: 4px;
  flex-grow: 1;
  overflow: hidden;
}

.uploader__label {
  font-size: 14px;
  line-height: 22px;
}

.uploader__name {
  margin-top: 2px;
  font-size: 15px;
  line-height: 18px;
  color: #5d6164;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50px;
}

.uploader__actions {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.uploader__actions .button {
  padding-left: 16px;
  padding-right: 16px;
}

@media (900px <= width < 1500px) {
  .uploader {
  }

  .uploader__handler {
    gap: 16px;
    padding: 6px;
  }

  .uploader__placeholder {
    width: 50px; /* Same as logo */
    margin-left: 3px; /* Same as logo */
  }

  .uploader__message {
    font-size: 14px;
    line-height: 16px;
  }

  .uploader__add {
    margin-right: 16px;
  }

  .uploader__item {
    margin-top: 12px;
    gap: 14px;
  }

  .uploader__item:first-child {
  }

  .uploader__preview {
    width: 50px; /* 10px smaller than column of buttons */
    margin-top: 3px; /* because it is smaller */
    margin-bottom: 3px; /* because it is smaller */
    margin-left: 3px; /* because it is smaller */
  }

  .uploader__data {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .uploader__label {
    font-size: 14px;
    line-height: 20px;
  }

  .uploader__name {
    margin-top: 5px;
    font-size: 14px;
    line-height: 16px;
  }

  .uploader__actions {
    gap: 6px;
  }
}

:disabled .uploader {
  pointer-events: none;
}
