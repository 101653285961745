.social-poster {
  position: absolute;
  top: 52%;
  left: 50%;
  width: 90%;
  max-width: 900px;
  height: 96vh;
  min-height: 600px;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.social-poster-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  height: auto;
  aspect-ratio: 5/7;
  min-height: 500px;
  background-color: white; /* Default brown background color */
  /* border-radius: 20px; */
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
}

.social-poster-container.template-2,
.social-poster-container.template-3 {
  background-color: #525252;
}

.social-poster-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  max-width: 800px;
  padding: 20px;
}

.social-poster-download-button {
}

.social-poster-close-button {
  position: relative; /* Changed from relative to absolute */
  right: 20px;
  margin-left: 10%;
  padding: 10px;
  z-index: 100;
  margin-top: 8%;
}

.top-left-logo {
  position: absolute;
  top: 50px;
  left: 20px;
  width: 100px;
  z-index: 100;
}

.logo {
  width: 180px;
  height: auto;
  border-radius: 100px;
}

.text-section {
  padding-left: 20px;
  margin-top: 82%; /* Adjust to position text properly */
  text-align: center;
  color: #04b290;
  overflow: visible;
  z-index: 1;
}

.place-name {
  text-align: center;
  margin: auto;
  font-size: 40px;
  width: 460px;
  /* margin-bottom: 50px; */
  line-height: 100%;
  font-weight: bolder;
  text-shadow: black 0px 0px 1px;
  /* background-color: aqua; */
}

.place-name.template-1 {
  color: black;
}

.place-name.template-3,
.place-name.template-2 {
  color: #ffeed7;
}

.preview_qr {
  border: 5px solid var(--primary-colour);
  border-radius: 30px;
  padding: 15px;
  position: absolute;
  display: inline-block;
  align-items: center;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 60%;
  max-width: 250px;
}

/* .preview_qr.template-1,
.preview_qr.template-3 {
  top: 55%;
} */

.qr_image {
  vertical-align: top;
  width: 100%;
  height: auto;
  max-height: 220px;
  aspect-ratio: 1/1;
}

/* .qr_image.template-1,
.qr_image.template-3 {
  height: 220px;
} */

.image-mask {
  height: 68%;
  width: 100%;
  display: absolute;
  position: absolute;
  top: 0;
  align-items: top;
  justify-content: top;
  border-radius: 0 0 50% 50% / 20%;
  overflow: hidden;
}

.image-mask.template-1,
.image-mask.template-3 {
  display: none;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.visit-button {
  position: absolute;
  background-color: white;
  color: black;
  border: none;
  padding: 5px 20px;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 800;
  cursor: pointer;
  left: 20px;
  bottom: 80px;
  box-shadow: gray 0px 0px 1px;
  width: 220px;
  height: 50px;
}

.powered-by-meed {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
}

.navigation-arrows {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 10;
}

.nav-arrow {
  background-color: #e06932;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.nav-arrow.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.template-dots {
  position: absolute;
  bottom: 10.9%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: clamp(5px, 1vw, 10px);
  z-index: 10;
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
}

.template-dot {
  width: clamp(8px, 1.5vw, 12px);
  height: clamp(8px, 1.5vw, 12px);
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Subtle shadow for better visibility */
}

.template-dot.active {
  background-color: var(--primary-colour);
}

.download-buttons {
  display: flex;
  gap: 20px;
  margin-top: 85px;
}

.download-button {
  padding: 10px 20px;
  border: 2.5px solid #e06932;
  border-radius: 10px;
  background-color: white;
  color: black;
  cursor: pointer;
  font-weight: bolder;
  width: 200px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.template-previews {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin: 20px auto;
  padding: 20px;
  max-width: 800px;
  background: #f5f5f5;
  border-radius: 12px;
  flex-wrap: wrap;
  width: 90%;
}

.template-preview {
  width: clamp(120px, 20vw, 180px);
  height: clamp(120px, 20vw, 180px);
  border: 2px solid #e8e8e8;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  background: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.template-preview:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.template-preview.active {
  border-color: var(--primary-colour);
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.template-preview img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.preview-container {
  padding: 10px;
  background: white;
}

@media (max-width: 768px) {
  .download-buttons {
    flex-direction: column;
    align-items: center;
  }

  .visit-button {
    width: 80%;
    max-width: 220px;
    left: 50%;
    transform: translateX(-50%);
  }
}
