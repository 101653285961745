.looker-dashboard {
  width: 100%;
  height: 100vh;
}

.dashbroadTab {
  display: flex;
  position: relative;
  height: 100vh;
  justify-content: flex-start;
  margin-left: 5%;
  margin-right: 5%;
}
.ant-tabs-nav {
  margin-bottom: 20px;
}

.ant-tabs-tab {
  padding: 10px 20px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #f0f0f0;
  margin-right: 8px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.ant-tabs-tab:hover {
  background-color: #e7faff; /* Lightened version of the branded color */
}

/* .ant-tabs-tab-active {
  background-color: #f87c56;
  color: white !important;
  border-color: #f87c56;
} */
