.user {
  position: relative;
}

.user__handler {
  display: inline-flex;
  align-items: center;
  gap: 16px;
  vertical-align: top;
  border: none;
  padding: 8px 16px 8px 14px;
  background-color: transparent;
  border-radius: 8px;
  font-family: inherit;
  cursor: pointer;
  transition: background-color var(--transition-hover);
}

.user__handler:hover {
  background-color: var(--hover);
}

.user__handler:active {
  background-color: var(--active);
}

.user__avatar {
  vertical-align: top;
  background-color: var(--image-fallback);
  border-radius: 50%;
  width: 64px;
  height: 64px;
}

.user__chevron {
  transition: transform var(--transition-dropdown);
}

.user__dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #ffffff;
  box-shadow: var(--shadow-over-grey-background);
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity var(--transition-dropdown),
    visibility var(--transition-dropdown);
}

.user__name {
  padding: 8px 18px 0;
  font-weight: var(--black);
  font-size: 20px;
  line-height: 24px;
  white-space: nowrap;
  text-decoration: none;
  color: inherit;
}

.user__email {
  padding: 4px 18px;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
}

.user__organization {
  padding: 4px 16px;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
}

.user__link {
  display: flex;
  justify-content: space-between;
  padding: 4px 18px;
  font-size: 16px;
  line-height: 24px;
  color: inherit;
  white-space: nowrap;
  text-decoration: none;
}

.user__link:hover {
  background-color: var(--hover);
}

.user__separator {
  margin: 10px 18px;
  height: 1px;
  background-color: currentColor;
}

.user--expanded {
}

.user--expanded .user__handler {
  background-color: var(--active);
}

.user--expanded .user__chevron {
  transform: scaleY(-1);
}

.user--expanded .user__dropdown {
  opacity: 1;
  visibility: visible;
}

@media (900px <= width < 1500px) {
  .user {
  }

  .user__handler {
    gap: 12px;
    padding: 5px 12px 5px 10px;
    border-radius: 6px;
  }

  .user__avatar {
    width: 50px;
    height: 50px;
  }

  .user__chevron {
    width: 14px;
    height: 14px;
  }

  .user__dropdown {
    /*margin-top: 8px;*/
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 6px;
  }

  .user__name {
    padding: 6px 15px 0;
    font-size: 18px;
    line-height: 22px;
  }

  .user__email {
    padding: 5px 15px;
    font-size: 15px;
    line-height: 18px;
  }

  .user__link {
    padding: 5px 15px;
    font-size: 15px;
    line-height: 18px;
  }

  .user__separator {
    /*margin: 10px 18px;*/
  }
}

.organization-list {
  position: relative;
}

.organization-list__dropdown {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 250px;
  transform: translate(calc(-100% - 4px), -20px);
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #ffffff;
  box-shadow: var(--shadow-over-grey-background);
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity var(--transition-dropdown),
    visibility var(--transition-dropdown);
}

.organization-list:hover .organization-list__dropdown {
  opacity: 1;
  visibility: visible;
}

.organization-list__dropdown__link {
  display: block;
  padding: 4px 18px;
  font-size: 16px;
  line-height: 24px;
  color: inherit;
  white-space: nowrap;
  text-decoration: none;
}

.organization-list__dropdown__link--active {
  font-weight: bold;
}

.organization-list__dropdown a:hover {
  background-color: var(--hover);
}
