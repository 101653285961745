.paypal-button-container {
  display: flex;
  justify-content: center;
  align-items: center; /* Adjust the margin as needed */
  width: 100%; /* Ensure the container takes up the full width */
}

.paypal-button-wrapper {
  width: 100%; /* Ensure the button takes up the full width of the container */
  max-width: 400px; /* Set a maximum width for the button */
}
