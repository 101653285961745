.workspace {
  padding: 6px 22px 10px;
}

.workspace__current {
  position: relative;
}

.workspace__current::after {
  content: "";
  position: absolute;
  left: -6px;
  right: -6px;
  bottom: 0;
  display: block;
  height: 1px;
  background-color: #5d6164;
}

.workspace__scroll {
  max-height: calc(100vh - 230px);
  margin-left: -21px;
  margin-right: -21px;
  padding-left: 21px;
  padding-right: 21px;
  overflow-y: auto;
  overflow-x: hidden;
}

.workspace__list {
  margin-top: 4px;
  gap: 40px;
}

.workspace__item {
}

.workspace__show-all {
}

.workspace__add {
}

.workspace__list + .workspace__add {
  margin-top: 4px;
}

.workspace--collapsed {
}

.workspace--collapsed .workspace__list {
  columns: 1 !important;
}

.workspace--collapsed .workspace__item:nth-child(n + 5) {
  display: none;
}

.workspace:not(.workspace--collapsed) .workspace__show-all {
  display: none;
}
