.user-management {
  margin-top: 10px;
  flex-grow: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.user-management__heading {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.user-management__body {
  margin-top: calc(var(--spacing) - 8px);
  padding-bottom: calc(var(--spacing) / 4);
  display: flex;
  justify-content: space-between;
}

.user-management__body__fieldset {
  border: 0 none;
  padding: 0;
  margin: 0;
  width: 100%;
  color: #333333;
}

.user-management__body table {
  vertical-align: top;
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  box-shadow: var(--shadow-over-grey-background);
  border-radius: var(--card-radius);
  color: #333333;
}

.user-management__body thead tr {
  border-bottom: 1px solid #ddd;
  color: #333333;
}

.user-management__body tbody tr:nth-child(odd) {
  background-color: #e5e8eb;
}

.user-management__body th {
  padding: 14px 20px;
  text-align: center;
  color: #333333;
}

.user-management__body td {
  padding: 14px 20px;
  text-align: center;
}

.user-management__body tr:last-child td:first-child {
  border-bottom-left-radius: var(--card-radius);
}

.user-management__body tr:last-child td:last-child {
  border-bottom-right-radius: var(--card-radius);
}

.user-management__avatar {
  vertical-align: top;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.user-management__email {
  text-decoration: none;
  color: inherit;
}

.user-management__roles {
  display: inline-flex;
  align-items: center;
  gap: 20px;
}

.user-management__add-button {
  background: #e06932;
  box-shadow: 3px 3px 3px #9999;
  font-family: "Questrial", sans-serif;
  font-weight: 600;
  color: #fff;
  border: 0 none;
  border-radius: 4px;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.user-management__add-button:hover {
  background: #ff9f80;
}
