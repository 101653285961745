.scrollbar-width-check {
  position: absolute;
  right: 200%;
  bottom: 200%;
  width: 100px;
  height: 100px;
  overflow-y: scroll;
}
.scrollbar-width-check__inner {
  width: 100%;
  height: 300px;
}
