/* Reset all parent containers */
#root,
.page,
.workspace,
.workspace__content,
.voucher.container,
.voucher__filter,
.filter,
.filter__item {
  overflow: visible !important;
}

/* Increase stacking context */
.ant-tooltip {
  position: fixed !important;
  z-index: 99999 !important;
}

/* Ensure tooltip trigger has proper stacking */
.voucher__filter {
  position: relative;
}

/* Ensure filter items can trigger tooltips */
.filter__item {
  position: relative;
}

.mp-content-card {
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.selected-card {
  margin-bottom: 20px;
  width: calc(250px - 10px);
  height: calc(40% - 10px);
  border: 5px solid var(--orange);
  background-color: white;
  margin-right: 2%;
  border-radius: 10px;
  box-shadow: 0 0 7px var(--orange);
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
}

.loc-name {
  width: 80px;
}

.mv-content {
  width: 100%;
  height: 80px;
  /* background-color: var(--orange); */
  margin-top: 10px;
  padding: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.mv-content-1 {
  width: 100%;
  height: 45%;
  /* background-color: var(--orange); */
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mv-div {
  height: 80%;
  width: 1px;
  background-color: gray;
}

.mv-mi,
.mv-mu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.mv-edit {
  width: 48%;
  height: 100%;
  display: flex;
  /* padding: 5px; */
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  background-color: grey;
}

.mv-btn-vc-toggle {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: var(--orange);
}

.mv-btn-vc-edit {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.mv-vc-toggle {
  width: 48%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  background-color: var(--orange);
}

.wel_voucher {
  margin-top: 12px;
  display: flex;
  align-items: center;
  background-color: rgb(229, 232, 235);
  padding: 4%;
  border-radius: 10px;
  justify-content: space-between;
}

.wel_voucher__label {
  padding-top: 2px;
  font-size: 14px;
  line-height: 16px;
}

.wel_voucher__widget {
  margin-left: auto;
  padding-left: 20px;
}

.voucher__filter {
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
  overflow: visible !important;
}

.voucher__filter .filter:last-child {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background: white;
}

.voucher__filter .filter:last-child .filter__item {
  border: none;
  background: none;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  overflow: visible !important;
  position: relative;
}

.voucher__filter .filter:last-child .filter__item--active {
  background: var(--orange);
  color: white;
}

.voucher__filter .filter:last-child .filter__item:hover {
  background: var(--orange-light);
  color: var(--orange);
}

.voucher__filter .filter:last-child .filter__item--active:hover {
  background: var(--orange);
  color: white;
}

.voucher.container {
  position: relative;
  overflow: visible !important;
}

.ant-tooltip {
  z-index: 9999 !important;
  pointer-events: auto !important;
}

.voucher__body,
.voucher__heading {
  overflow: visible !important;
}
