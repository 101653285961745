.shop {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.shop__viewport {
  position: relative;
  width: 70%;
  padding-bottom: 56.25%;
  background-image: url("shop.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.shop__handler {
  position: absolute;
  border: none;
  padding: 0;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
}

.shop__handler:hover {
  filter: brightness(1.1) contrast(1.1);
}

.shop__handler--a4-poster {
  left: 83.4375%; /* 2136 / 2560 * 100 */
  width: 4.6484375%; /* 119 / 2560 * 100 */
  top: 35.6527%; /* 499 / 1440 * 100 */
  height: 24.375%; /* 351 / 1440 * 100 */
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTE5IiBoZWlnaHQ9IjM1MSIgdmlld0JveD0iMCAwIDExOSAzNTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTExNy41IDM1MEwzNC44NyAzMzEuMDg1QzM0LjYzMTYgMzMxLjAzIDM0LjQyMTUgMzMwLjg5IDM0LjI3OTQgMzMwLjY5MUwzMiAzMjcuNUwzMCAzMjVMMjcgMzIyTDI1LjUgMzIwLjVMMjQuNjM2MiAzMTkuNjM2QzI0LjU0NjIgMzE5LjU0NiAyNC40NzQzIDMxOS40NCAyNC40MjQxIDMxOS4zMjNMMjMgMzE2TDIwLjUgMzEyLjVMMTkuMyAzMTAuOUMxOS4xMTExIDMxMC42NDggMTguODE0OCAzMTAuNSAxOC41IDMxMC41SDE4LjA1OUMxNy43MTY0IDMxMC41IDE3LjQwMzIgMzEwLjMwNiAxNy4yNSAzMTBDMTcuMDk2OCAzMDkuNjk0IDE2Ljc4MzYgMzA5LjUgMTYuNDQxIDMwOS41SDExLjkxNDJDMTEuNjQ5IDMwOS41IDExLjM5NDYgMzA5LjM5NSAxMS4yMDcxIDMwOS4yMDdMMTEuMTE0NyAzMDkuMTE1QzExLjAzODcgMzA5LjAzOSAxMC45NTEgMzA4Ljk3NSAxMC44NTQ5IDMwOC45MjdMOS4xNzUyMSAzMDguMDg4QzkuMDU5NSAzMDguMDMgOC45MzM2NyAzMDcuOTk1IDguODA0NjkgMzA3Ljk4NUwyLjk1MTU2IDMwNy41MzVDMi42NjQ1NSAzMDcuNTEzIDIuNDAwOTggMzA3LjM2OCAyLjIyODI2IDMwNy4xMzhMMS4yIDMwNS43NjdDMS4wNzAxOCAzMDUuNTk0IDEgMzA1LjM4MyAxIDMwNS4xNjdWMzAxLjczNkMxIDMwMS41ODEgMS4wMzYxNCAzMDEuNDI4IDEuMTA1NTcgMzAxLjI4OUwzLjk0OTQxIDI5NS42MDFDMy45ODMwMyAyOTUuNTM0IDQuMDI0MTIgMjk1LjQ3MSA0LjA3MTkxIDI5NS40MTNMMTAuMzc2NyAyODcuNzU3QzEwLjcyMjkgMjg3LjMzNiAxMC42NjkxIDI4Ni43MTYgMTAuMjU1NiAyODYuMzYyTDcuNzQ4MTggMjg0LjIxM0M3LjU4NjkzIDI4NC4wNzQgNy4zODU4NSAyODMuOTkxIDcuMTc0MDkgMjgzLjk3NUwxLjkyMzMgMjgzLjU3MUMxLjQwMjMxIDI4My41MzEgMSAyODMuMDk2IDEgMjgyLjU3NFYyNjcuNzY2QzEgMjY3LjU5MiAxLjA0NTQxIDI2Ny40MjEgMS4xMzE3NiAyNjcuMjY5TDQuMzQ1MDcgMjYxLjY0NkM0LjY3Mzg0IDI2MS4wNzEgNC4zNjIyNCAyNjAuMzQxIDMuNzE5MzcgMjYwLjE4TDEuNzU1ODkgMjU5LjY4OUMxLjMxMTQ2IDI1OS41NzggMC45OTkzNTQgMjU5LjE3OSAwLjk5ODQzMiAyNTguNzIxTDAuNSAxMUwxMTMuNSAxTDExNy41IDM1MFoiIGZpbGw9IiNGODdDNTYiLz48L3N2Zz4=);
}

.shop__handler--qr {
  left: 59.2187%; /* 1516 / 2560 * 100 */
  width: 4.2578%; /* 109 / 2560 * 100 */
  top: 14%; /* 0 / 0 * 100 */
  height: 7.7777%; /* 112 / 1440 * 100 */
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA5IiBoZWlnaHQ9IjExMiIgdmlld0JveD0iMCAwIDEwOSAxMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICAgIDxwYXRoIGQ9Ik0wIDExMlYwSDEwOVYxMTJIMFoiIGZpbGw9IiNGODdDNTYiLz48L3N2Zz4=);
}

.shop__handler--a4-voucher {
  left: 90.2343%; /* 2310 / 2560 * 100 */
  width: 6.6796%; /* 171 / 2560 * 100 */
  top: 3.3333%; /* 480 / 1440 * 100 */
  height: 28.3333%; /* 408 / 1440 * 100 */
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcxIiBoZWlnaHQ9IjQwOCIgdmlld0JveD0iMCAwIDE3MSA0MDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIgMTdMMTY5IDFMMTY3IDQwNy41TDIgMzc2LjVWMTdaIiBmaWxsPSIjRjg3QzU2Ii8+PC9zdmc+);
}

.shop__handler--a5-voucher {
  left: 9.8828%; /* 253 / 2560 * 100 */
  width: 4.3359%; /* 111 / 2560 * 100 */
  top: 28.5555%; /* 368 / 1440 * 100 */
  height: 22.43055%; /* 323 / 1440 * 100 */
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTExIiBoZWlnaHQ9IjMyMyIgdmlld0JveD0iMCAwIDExMSAzMjMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEwOS41IDE0LjVMMSAxVjMyM0wxMDYuNSAzMTZMMTA5LjUgMTQuNVoiIGZpbGw9IiNGODdDNTYiLz48L3N2Zz4=);
}

.shop__handler--a6-voucher-a {
  left: 41.3359%; /* 943 / 2560 * 100 */
  width: 3.4843%; /* 166 / 2560 * 100 */
  top: 62.35%; /* 990 / 1440 * 100 */
  height: 3.4722%; /* 50 / 1440 * 100 */
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjgxIiBoZWlnaHQ9IjQ4Ij4KPHBhdGggZD0iTTAgMCBDOC4xNzk2MzQ4OCAtMC4wMjMxNDM2NyAxNi4zNTkyNTYxMyAtMC4wNDA5MjU0NCAyNC41Mzg5MTU2MyAtMC4wNTE4MTIxNyBDMjguMzM2ODI3ODkgLTAuMDU3MDM2NTYgMzIuMTM0NzE3OCAtMC4wNjQxMjYxNyAzNS45MzI2MTcxOSAtMC4wNzU0Mzk0NSBDMzkuNTk2MDA2NzMgLTAuMDg2Mjg0MDggNDMuMjU5Mzc0NzMgLTAuMDkyMjgwMTkgNDYuOTIyNzc5MDggLTAuMDk0ODc3MjQgQzQ4LjMyMjI1NTA5IC0wLjA5NjcyODY0IDQ5LjcyMTcyOTg4IC0wLjEwMDM0MzgyIDUxLjEyMTE5Njc1IC0wLjEwNTczMDA2IEM1My4wNzczNjgyMiAtMC4xMTI5NjEyNiA1NS4wMzM1NjEyNiAtMC4xMTM0MDgyMyA1Ni45ODk3NDYwOSAtMC4xMTM1MjUzOSBDNTguNjYxODY2NjEgLTAuMTE2ODU2MzEgNTguNjYxODY2NjEgLTAuMTE2ODU2MzEgNjAuMzY3NzY3MzMgLTAuMTIwMjU0NTIgQzYzIC0wIDYzIC0wIDY1IDEgQzY0LjI2MjcwODQ1IDguMDU4MjE3OTMgNjIuOTE3NTU4NjMgMTQuOTcwNjYzOTMgNjEuNTI1ODc4OTEgMjEuOTI2NTEzNjcgQzYxLjEyNjgyNjU0IDIzLjkyODMzNzI5IDYwLjczNzA1NzEyIDI1LjkzMTgyNjUxIDYwLjM0NzY1NjI1IDI3LjkzNTU0Njg4IEM2MC4wOTExMzI4MSAyOS4yMjM5NjQ4NCA1OS44MzQ2MDkzNyAzMC41MTIzODI4MSA1OS41NzAzMTI1IDMxLjgzOTg0Mzc1IEM1OS4zNDEwMjA1MSAzMi45OTg2MzAzNyA1OS4xMTE3Mjg1MiAzNC4xNTc0MTY5OSA1OC44NzU0ODgyOCAzNS4zNTEzMTgzNiBDNTcuODQ2NjAyMTcgMzkuNjM5MzAwMjMgNTYuMzk0NDY3NDcgNDMuODE2NTk3NiA1NSA0OCBDMzEuNTcgNDggOC4xNCA0OCAtMTYgNDggQy0xMi42NTEyNjY3NyAzNS43MjEzMTE0OCAtMTIuNjUxMjY2NzcgMzUuNzIxMzExNDggLTEwLjYyNSAyOS44MTI1IEMtOS4yNTM4MjI1NiAyNS43ODg2NjU5OCAtNy45MTMxMTg5NSAyMS43NjEzOTMzNSAtNi42NzE4NzUgMTcuNjk1MzEyNSBDLTYuNDU0NTA2ODQgMTYuOTgzOTkxNyAtNi4yMzcxMzg2NyAxNi4yNzI2NzA5IC02LjAxMzE4MzU5IDE1LjUzOTc5NDkyIEMtNS42MDAxOTIzMyAxNC4xNzkzMjUyMyAtNS4xOTEzODExNyAxMi44MTc1NzY1OSAtNC43ODc1OTc2NiAxMS40NTQzNDU3IEMtMy41NTI4Mjg2OCA3LjM5NTA4MTkgLTEuOTMyNTQ0MzYgMy43Njg2OTMzOCAwIDAgWiAiIGZpbGw9IiNGODdDNTYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE2LDApIi8+Cjwvc3ZnPgo=);
}
.shop__handler--a6-voucher-b {
  left: 37.8359%; /* 943 / 2560 * 100 */
  width: 3.4843%; /* 166 / 2560 * 100 */
  top: 62.45%; /* 990 / 1440 * 100 */
  height: 3.4722%; /* 50 / 1440 * 100 */
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9Ijg1IiBoZWlnaHQ9IjQ4Ij4KPHBhdGggZD0iTTAgMCBDMjEuNDUgMCA0Mi45IDAgNjUgMCBDNjIuNzUgMTAuMTI1IDYyLjc1IDEwLjEyNSA2MS41Nzk1ODk4NCAxMy42MDE4MDY2NCBDNjEuMzE1ODk2IDE0LjM5MjUyNTYzIDYxLjA1MjIwMjE1IDE1LjE4MzI0NDYzIDYwLjc4MDUxNzU4IDE1Ljk5NzkyNDggQzYwLjUxMDA1NjE1IDE2Ljc5Mzk2MTE4IDYwLjIzOTU5NDczIDE3LjU4OTk5NzU2IDU5Ljk2MDkzNzUgMTguNDEwMTU2MjUgQzU5LjU4MDYyMzc4IDE5LjU0NjU2NTU1IDU5LjU4MDYyMzc4IDE5LjU0NjU2NTU1IDU5LjE5MjYyNjk1IDIwLjcwNTkzMjYyIEM1OC4yNTgzOTIwNiAyMy40OTMxMjcyNyA1Ny4zMTYyNTE3IDI2LjI3NzY2NzEzIDU2LjM3NSAyOS4wNjI1IEM1NC4yNzEyNSAzNS4zMTE4NzUgNTIuMTY3NSA0MS41NjEyNSA1MCA0OCBDMjYuOSA0OCAzLjggNDggLTIwIDQ4IEMtMTYuNjI1IDM3Ljg3NSAtMTYuNjI1IDM3Ljg3NSAtMTUuMDk4ODc2OTUgMzQuMzk4MTkzMzYgQy0xNC43NTQwOTMwMiAzMy42MDc0NzQzNyAtMTQuNDA5MzA5MDggMzIuODE2NzU1MzcgLTE0LjA1NDA3NzE1IDMyLjAwMjA3NTIgQy0xMy41MjY1MDgxOCAzMC44MDgwMjA2MyAtMTMuNTI2NTA4MTggMzAuODA4MDIwNjMgLTEyLjk4ODI4MTI1IDI5LjU4OTg0Mzc1IEMtMTIuNjU3MTEzMDQgMjguODMyMjM3NTUgLTEyLjMyNTk0NDgyIDI4LjA3NDYzMTM1IC0xMS45ODQ3NDEyMSAyNy4yOTQwNjczOCBDLTEwLjc2NDk0NzEgMjQuNTA2NjY1MjcgLTkuNTM4MzQwMzggMjEuNzIyMjQ4MzkgLTguMzEyNSAxOC45Mzc1IEMtNS41NjkzNzUgMTIuNjg4MTI1IC0yLjgyNjI1IDYuNDM4NzUgMCAwIFogIiBmaWxsPSIjRjg3QzU2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMCwwKSIvPgo8L3N2Zz4K);
}

.shop__hidden-posters-to-print {
  position: absolute;
  right: 100%;
  bottom: 100%;
}
